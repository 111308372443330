
<template>
  <v-card flat class="mx-auto primaryBackground" width="100%">
    <WaitingRoom
      v-if="showWaitingRoom && hasPrivilege(ownUUID)"
      :active="active"
      :waitingRoomFor="ownUUID"
      :setList="setList"
      :filterPerson="filterPerson"
      :mini="mini"
    />
    <!-- add waiting room for assistant -->
    <template v-if="showWaitingRoom && assistantsUser">
      <WaitingRoomFor
        v-for="uuid in assistantsUser"
        :key="uuid"
        :waitingRoomFor="uuid"
        :active="active"
        :setList="setList"
        :filterPerson="filterPerson"
        :mini="mini"
      />
    </template>
    <Conference
      :active="active"
      :setList="setList"
      :isGuest="isGuest"
      :isMobile="isMobile"
      :mini="mini"
      :setCurrentContentVisile="setCurrentContentVisile"
      v-if="!isMobile && !isIpad"
    />
    <MarriageProject
      :active="active"
      :setList="setList"
      :isGuest="isGuest"
      :isMobile="isMobile"
      :openSidebar="openSidebar"
      :mini="mini"
      :setCurrentContentVisile="setCurrentContentVisile"
      v-if="!isMobile && !isIpad && marriageProject.length && !inBridgeConference"
    />
    <OrganisationTree
      :mini="mini"
      :openSidebar="openSidebar"
      :closeOnClick="closeOnClick"
      :closeOnContent="closeOnContent"
      :sectionSelected="sectionSelected"
      :handleOrganisation="handleOrganisation"
      :setActiveOrganisationSection="setActiveOrganisationSection"
      :setList="setList"
      :isMobile="isMobile"
      :getTotalTeams="getTotalTeams"
      :sendToEntities="sendToEntities"
      :sendToEntitiesSelected="sendToEntitiesSelected"
      :active="active"
      v-if="
        !oneCallAndWaitingUser(ownUUID) &&
        hasPrivilege(ownUUID) &&
        (featureOrganizationOption || amIAdmin) &&
        !isBasicView
      "
    />
    <CoffeeBreak
      v-if="state.namespaceSettings.showCoffeeBreak"
      :setList="setList"
      :active="active"
      :setCurrentContentVisile="setCurrentContentVisile"
      :isGuest="isGuest"
      :sortedCoffeeBreakUsers="sortedCoffeeBreakUsers"
      :isMobile="isMobile"
      :mini="mini"
    />
    <SambaRoom
      :setList="setList"
      :active="active"
      :setCurrentContentVisile="setCurrentContentVisile"
      :isGuest="isGuest"
      :isMobile="isMobile"
      :mini="mini"
      v-if="!isMobile && !isIpad"
    />
    <SupportCall
      :isGuest="isGuest"
      :isMobile="isMobile"
      :mini="mini"
    />
  </v-card>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../store";
import { isMobile, isIpad } from "../../lib/mobileUtil";
import Users from "./users.vue";
import Favorites from "./favorites.vue";
import Guests from "./guests.vue";
import Visitors from "./visitors.vue";
import Invites from "./invites.vue";
import Stats from "./stats.vue";
import Conference from "./conference.vue";
import MarriageProject from "./marriageProject.vue";
import NewConference from "./newConferenceSidebar.vue";
import infoboard from "./infoboard.vue";
import OrganisationTree from "./organisation/organisationTree.vue";
import WaitingRoom from "./waitingRoom.vue";
import WaitingRoomFor from "./waitingRoomFor.vue";
import { myConferencesStore } from "../../effector/conference";
import CoffeeBreak from "./coffeeBreak.vue";
import SambaRoom from "./sambaRoom.vue"
import SupportCall from "./supportCall.vue"
import {
  oneCallAndWaitingUser,
  hasPrivilege,
  basicView,
} from "../../utils/privileges";
import { amInABridgeCall, isConferenceCall, amInAStaticRoom } from "../../utils/calls";
import { checkListSort } from "../../utils/basicFunctions";
const hasOwn = Object.prototype.hasOwnProperty;
export default {
  components: {
    Users,
    Favorites,
    MarriageProject,
    Guests,
    Visitors,
    Stats,
    Conference,
    NewConference,
    infoboard,
    OrganisationTree,
    WaitingRoom,
    WaitingRoomFor,
    Invites,
    CoffeeBreak,
    SupportCall,
    SambaRoom
  },
  props: ["setList", "active", "mini", "openSidebar"],
  data() {
    const effector = {
      myConferencesStore: myConferencesStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      sendToEntitiesSelected: [],
      closeOnClick: true,
      closeOnContent: false,
      isMobile: isMobile(),
      isIpad: isIpad(),
      positionArrayWaitingroom: 0,
      // Effector
      ...effector,
    }
  },
  mounted() {
    EventBus.$on("openWaitingRoomEvent", this.goToWaitingRoom);
  },
  unmounted(){
    EventBus.$off("openWaitingRoomEvent", this.goToWaitingRoom);
  },
  watch: {
    $route(to, from) {
      if (to.path !== "/messages") {
        store.setSubordinateUser("");
      }
      if (
        to.path !== "/teams" ||
        to.path !== "/sections" ||
        to.path !== "/departments"
      ) {
        store.state.orgaSearchTerm.searching = "";
      }
      store.state.user.activeGroupName = "";
    },
  },
  methods: {
    goToWaitingRoom() {
      if (this.getWaitingRoomList && this.getWaitingRoomList.length > 0) {
        if (
          this.positionArrayWaitingroom >
          this.getWaitingRoomList.length - 1
        ) {
          this.positionArrayWaitingroom = 0;
        }
        if (
          this.state.ownUUID ===
          this.getWaitingRoomList[this.positionArrayWaitingroom]
        ) {
          store.setCurrentContentVisile("waitingroom", true, this.$router);
        } else {
          store.setCurrentContentVisile(
            `waitingroomfor/${
              this.getWaitingRoomList[this.positionArrayWaitingroom]
            }`,
            true,
            this.$router
          );
        }
        this.positionArrayWaitingroom += 1;
      }
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    oneCallAndWaitingUser(uuid) {
      return oneCallAndWaitingUser(uuid);
    },
    sectionSelected(event, item) {
      // select /deselect all
      if (this.sendToEntitiesSelected.indexOf(1) !== -1 && item === 1) {
        // all is not checked
        this.selectAllUsers();
      } else if (this.sendToEntitiesSelected.indexOf(1) === -1 && item === 1) {
        this.deselectAllUsers();
      }
      // select deselect item
      if (item !== 1) {
        //if selected and unchecking
        if (this.sendToEntitiesSelected.indexOf(item) === -1) {
          //unchecking
          this.removetAllUsersChoice();
        }
      }
    },
    removetAllUsersChoice() {
      for (let index = 0; index < this.sendToEntitiesSelected.length; index++) {
        const element = this.sendToEntitiesSelected[index];
        if (element === 1) {
          this.sendToEntitiesSelected.splice(index, 1);
        }
      }
    },
    selectAllUsers() {
      this.sendToEntitiesSelected = [1, 2, 3, 4];
    },
    deselectAllUsers() {
      this.sendToEntitiesSelected = [];
    },
    handleOrganisation: function () {
      if (this.$route.path !== "/organisation") {
        this.setCurrentContentVisile("organisation", true, this.$router);
      } else if (this.$route.path == "/organisation") {
        this.setCurrentContentVisile("", false, this.$router);
      }
      store.setOrganisationEditableView(false);
      store.state.showSearch = false;
    },
    setActiveOrganisationSection(section) {
      return store.setActiveOrganisationSection(section);
    },
    filterPerson: function (person) {
      return (
        person && person.user?.email &&
        person.user.email !== '' &&
        person.uuid != this.ownUUID &&
        (this.state.searchTerm.length === 0 ||
          ((person.user.name || "").trim().toLowerCase() || "").indexOf(
            this.state.searchTerm.trim().toLowerCase()
          ) !== -1)
      );
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
  },
  computed: {
    inBridgeConference() {
      const callUUID = Object.keys(this.state.remoteBridgeStreams)[0];
      return amInABridgeCall() && (isConferenceCall(callUUID) || amInAStaticRoom());
    },
    marriageProject() {
      return store.state.namespaceSettings.marriageProject;
    },
    sortedCoffeeBreakUsers() {
      const coffeeBreakPersons = [];
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (!person || !person.user) continue;
        if (person.connected && person.user.presentFrom) {
          if (person.user.activity === "Coffee break") {
            coffeeBreakPersons.push({ ...person, uuid });
          }
        }
      }
      return checkListSort(coffeeBreakPersons);
    },
    getWaitingRoomList() {
      let uuids = [this.ownUUID];
      if (this.assistantsUser) {
        this.assistantsUser.forEach((uuid) => {
          if (uuid) uuids.push(uuid);
        });
      }
      return uuids;
    },
    isBasicView() {
      return basicView();
    },
    assistantsUser() {
      let finalResult = [];
      if (this.state.user.assists && this.state.user.assists.length > 0) {
        finalResult = JSON.parse(JSON.stringify(this.state.user.assists));
      }
      if (
        this.state.user.waitingRoomAssists &&
        this.state.user.waitingRoomAssists.length > 0
      ) {
        this.state.user.waitingRoomAssists.forEach((uuid) => {
          if (finalResult.indexOf(uuid) == -1) {
            finalResult.push(uuid);
          }
        });
      }
      return finalResult.filter(uuid => hasOwn.call(this.state.group, uuid) && !!this.state.group[uuid]);
    },
    featureOrganizationOption() {
      return store.state.namespaceSettings.featureOrganization;
    },
    getTotalTeams() {
      return Object.keys(
        (store.state.namespaceSettings.processedOrganisation || {}).teams || {}
      ).length;
    },
    sendToEntities() {
      return [
        { id: 1, name: this.$t("components.drawerContentUser.allUsers") },
        {
          id: 2,
          name: this.$t("components.drawerContentUser.allSectionManagers"),
        },
        {
          id: 3,
          name: this.$t("components.drawerContentUser.allDepartmentManagers"),
        },
        {
          id: 4,
          name: this.$t("components.drawerContentUser.allTeamManagers"),
        },
      ];
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    showWaitingRoom() {
      return this.state.user.userSettings.showWaitingRoom;
    },
    sortedPersons: function () {
      const persons = [];
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        const isGuest =
          person.permissions === 10 ||
          !person.user ||
          person.user.guest ||
          person.user.visitor;
        if (
          !person ||
          !person.user ||
          person.user.name == "" ||
          isGuest ||
          person.user.rtcCallStart == undefined
        )
          continue;
        persons.push({ ...person, uuid });
      }
      return persons.sort((a, b) => {
        // Compare by connected state
        if (a.connected && !b.connected) {
          return -1;
        } else if (!a.connected && b.connected) {
          return 1;
        }
        return ((a.user || {}).name || a.uuid || "").localeCompare(
          (b.user || {}).name || b.uuid || ""
        );
      });
    },
    sortedFavoriteUsers: function () {
      const favoritePersons = [];
      for (let uuid in store.state.group) {
        const exists = store.getUuidFromFavorites(uuid);
        if (exists) {
          const person = store.state.group[uuid];
          if (!person || !person.user) {
            continue;
          }
          favoritePersons.push({ ...person, uuid });
        }
      }
      return favoritePersons.filter(this.filterPerson).sort((a, b) => {
        if (a.connected && !b.connected) {
          return -1;
        } else if (!a.connected && b.connected) {
          return 1;
        }
        return ((a.user || {}).name || a.uuid || "").localeCompare(
          (b.user || {}).name || b.uuid || ""
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.select-box {
  background-color: var(--v-primary-base) !important;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item__title {
  line-height: 2.5 !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-expansion-panel-header {
  padding: 0px 25px 0px 16px !important;
}
.v-expansion-panel-header__icon {
  min-width: 48px !important;
  margin-right: 8px !important;
}
</style>