<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="700"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="type === 'Holidays' ? $t('components.holidays.setYourHolidays') : $t('components.holidays.setYourIll')"
        :closeModalFunction="closeModal"
        :showInfoButton="type"
      />
      <v-card-text class="mx-1 px-0">
        <!-- <v-row class="mx-auto" flex>
          <v-col cols="6" align="center"
            >{{ $t("components.holidays.start") }}
            {{ getStartHolidaysFormatted }}</v-col
          >
          <v-col cols="6" align="center"
            >{{ $t("components.holidays.end") }}
            {{ getEndHolidaysFormatted }}</v-col
          >
        </v-row> -->
        <v-row  class="mx-auto holidaysDatePickers" flex>
          <v-col :cols="isMobile? '12':'6'" >
            <div class="mb-2 text-center">
            {{ $t("components.holidays.start") }}
            {{ getStartHolidaysFormatted }}</div>
            <v-date-picker
              no-title
              show-adjacent-months
              elevation="2"
              hide-header
              height="344"
              :min="getFirstDayOfThisYear"
              :model-value="startHolidays" 
              @update:model-value="startHolidays = $event; endHolidays=$event"
              :show-current="startHolidays"
              :pickTime="true"
              :format="'DD.MM.YYYY H:mm'"
              :locale="this.$locale.current()"
              first-day-of-week="1"
              width="320"
            ></v-date-picker>
          </v-col>
          <v-col :cols="isMobile? '12':'6'">
            <div class="mb-2 text-center">{{ $t("components.holidays.end") }}
            {{ getEndHolidaysFormatted }}</div>
            <v-date-picker
              :min="startHolidays"
              height="344"
              hide-header
              show-adjacent-months
              elevation="2"
              no-title
              :model-value="endHolidays" 
              @update:model-value="endHolidays = $event"
              :show-current="startHolidays"
              :pickTime="true"
              :format="'DD.MM.YYYY H:mm'"
              :locale="this.$locale.current()"
              first-day-of-week="1"
              width="320"
            ></v-date-picker>
          </v-col>
        </v-row>
        <v-row class="mx-auto py-0" flex>
          <v-col class="py-0 my-0" :cols="12">
            <v-radio-group
            v-if="type === 'Ill'"   
            hide-details 
            class="my-0 py-0" 
            inline 
            :model-value="isFullDay" 
            @update:model-value="isFullDay = $event">
              <v-radio
                :label="$t('status.fullDay')"
                :value="true"
                class="mr-4"
              ></v-radio>
              <v-radio
                :label="getNotFullDayLabel()"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row v-if="!isFullDay" class="mx-auto py-0 mb-2" flex>
          <v-col cols="6" class="pb-0 pl-4">
            <v-menu
              ref="menuStartPicker"
              :model-value="menuStartPicker" @update:model-value="menuStartPicker = $event"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="startTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ props }">
                <v-text-field
                  @keydown.space="(event) => event.preventDefault()"
                  :model-value="startTime" @update:model-value="startTime = $event"
                  type="time"
                  class="timePicker"
                  variant="outlined"
                  density="compact"
                  v-bind="props"
                  hide-details
                  :label="$t('components.conferenceForm.from')"
                ></v-text-field>
                <!-- @blur="$refs.menuStartPicker.save(startTime)" -->
              </template>
              <v-time-picker
                v-if="menuStartPicker"
                format="24hr"
                :model-value="startTime" @update:model-value="startTime = $event"
                full-width
                @update:minute="handleMinuteClick($event)"
                @update:hour="autocompleteMinutes($event)"
                min="00:00"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" class="pb-0 pr-4">
            <v-menu
              ref="menuEndPicker"
              :model-value="menuEndPicker"
              @update:model-value="menuEndPicker = $event"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="endTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ props }">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  @keydown.space="(event) => event.preventDefault()"
                  :model-value="endTime"
                  @update:model-value="endTime = $event"
                  type="time"
                  class="timePicker"
                  :label="$t('components.conferenceForm.to')"
                  :disabled="!startTime"
                  hide-details
                  v-bind="props"
                ></v-text-field>
                <!-- @blur="$refs.menuEndPicker.save(endTime)" -->
              </template>
              <v-time-picker
                v-if="menuEndPicker"
                format="24hr"
                :model-value="endTime" @update:model-value="endTime = $event"
                full-width
                @update:minute="handleMinuteClickEnd($event)"
                @update:hour="autocompleteMinutesEnd($event)"
                :min="startTime"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row class="mx-auto" flex>
          <v-col :cols="12">
            <v-combobox
              variant="outlined"
              :model-value="representative" @update:model-value="$event => (searchUsers = '', representative = $event)"
              :items="dataComboboxAllUsers"
              :search-input.sync="searchUsers"
              select
              :label="$t('excel.representative')"
              hide-details
              class="mb-5"
              density="compact"
              item-value="value"
              item-title="text"
              :return-object="true"
              @blur="selectItemOnBlur"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row class="mx-auto py-0" flex>
          <v-col class="py-0 my-0" :cols="12">
            <v-radio-group   
            v-if="!checkDisableType" 
            hide-details 
            class="my-0 py-0" 
            inline 
            :model-value="type" 
            @update:model-value="type = $event; onRadioChange(type)" 
            :disabled="checkDisableType">
              <v-radio
                :label="$t('status.Holidays')"
                value="Holidays"
                class="mr-4"
              ></v-radio>
              <v-radio
                :label="$t('status.Ill')"
                value="Ill"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <FooterModal :showCancel="true" :closeModalFunction="closeModal">
        <v-btn
          :disabled="disabledSave"
          flat
          color="primary"
          class="ml-2"
          @click="deleteHolidays"
          >{{ $t("components.holidays.deleteHolidays") }}</v-btn
        >
        <v-btn
          color="primary"
          flat
          class="ml-2"
          :disabled="disabledSave"
          @click="saveHolidays"
          >{{ $t("generics.save") }}</v-btn
        >
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "../../../sharedsrc/moment";
import { isMobile } from "../../lib/mobileUtil";
import store from "../../store";
import { hasPrivilege } from "../../utils/privileges.js";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";

export default {
  components: { HeaderModal, FooterModal },
  props: ["showModal", "closeModal", "userUUID", "calendarStatus"],
  data: () => ({
    state: store.state,
    startHolidays: null,
    endHolidays: null,
    moment,
    isMobile: isMobile(),
    searchUsers: null,
    representative: "",
    initialRepresentative: "",
    type: "Holidays",
    isFullDay: true,
    menuStartPicker: false,
    menuEndPicker: false,
    startTime: null,
    endTime: null,
  }),
  mounted: function () {
    if (this.calendarStatus) {
      this.type = this.calendarStatus;
    }
    if (this.type === 'Holidays' && store.state.group[this.userUUID].user?.startHolidays && store.state.group[this.userUUID].user?.endHolidays) {
      this.startHolidays = new Date(store.state.group[this.userUUID].user.startHolidays);
      this.endHolidays = new Date(store.state.group[this.userUUID].user.endHolidays);
      this.type = 'Holidays';
    } else if (this.type === 'Ill' && store.state.group[this.userUUID].user?.startIllness && store.state.group[this.userUUID].user?.endIllness) {
      this.startHolidays = new Date(store.state.group[this.userUUID].user.startIllness);
      this.endHolidays = new Date(store.state.group[this.userUUID].user.endIllness);
      this.type = 'Ill';
      // Partial-day absence tracking: #512
      if (/T/.test(store.state.group[this.userUUID].user.endIllness) && this.endHolidays < new Date()) {
        this.startHolidays = null;
        this.endHolidays = null;
        this.isFullDay = true;
        this.startTime = null;
        this.endTime = null;
      } else if (/T/.test(store.state.group[this.userUUID].user.startIllness)) {
        this.isFullDay = false;
        this.startTime = moment(store.state.group[this.userUUID].user.startIllness).format('HH:mm');
        this.endTime = moment(store.state.group[this.userUUID].user.endIllness).format('HH:mm');
      }
    }
    this.representative = store.state.group[this.userUUID].user?.representative;
    this.initialRepresentative = store.state.group[this.userUUID].user?.representative;
  },
  methods: {
    getNotFullDayLabel() {
      return !this.startTime || !this.endTime ? this.$t('status.notFullDay') : this.$t('status.from') + ' ' + this.startTime + ' ' + this.$t('status.to') + ' ' + this.endTime;
    },
    autocompleteMinutesEnd(hour) {
      let finalHour = hour;
      if (hour < 10) finalHour = '0' + hour;
      this.endTime = finalHour + ":00";
    },
    handleMinuteClickEnd(minutes) {
      const hour = this.endTime.slice(0,2);
      let newtimeEnd = hour + ':';
      if (minutes > 9) {
        newtimeEnd += minutes;
      } else {
        newtimeEnd += '0' + minutes;
      }
      if (newtimeEnd) {
        this.endTime = newtimeEnd;
        this.menuEndPicker = false;
      }
    },
    autocompleteMinutes(hour) {
      let finalHour = hour;
      if (hour < 10) finalHour = '0' + hour;
      this.startTime = finalHour + ":00";
    },
    handleMinuteClick(minutes) {
      const hour = this.startTime.slice(0,2);
      let newTimeStart = hour + ':';
      const newTimeEnd = hour + ':' + (minutes + 10 <= 59 ? minutes + 10 : '59'); //Set TimeEnd 10 mins later for reference
      if (minutes > 9) {
        newTimeStart += minutes;
      } else {
        newTimeStart += '0' + minutes;
      }
      if (newTimeStart) {
        this.startTime = newTimeStart;
        this.endTime = newTimeEnd;
        this.menuStartPicker = false;
      }
    },
    onRadioChange(value) {
      this.type = value;
      if (value === 'Holidays') {
        this.startHolidays = store.state.group[this.userUUID].user.startHolidays ? new Date(store.state.group[this.userUUID].user.startHolidays) : new Date();
        this.endHolidays = store.state.group[this.userUUID].user.endHolidays ? new Date(store.state.group[this.userUUID].user.endHolidays) : new Date();
        this.isFullDay = true; // Partial-day absence tracking: #512
      } else if (value === 'Ill') {
        this.startHolidays = store.state.group[this.userUUID].user.startIllness ? new Date(store.state.group[this.userUUID].user.startIllness) : new Date();
        this.endHolidays = store.state.group[this.userUUID].user.endIllness ? new Date(store.state.group[this.userUUID].user.endIllness) : new Date();
        // Partial-day absence tracking: #512
        if (/T/.test(store.state.group[this.userUUID].user.endIllness) && this.endHolidays < new Date()) {
          this.startHolidays = null;
          this.endHolidays = null;
          this.isFullDay = true;
          this.startTime = null;
          this.endTime = null;
        } else if (/T/.test(store.state.group[this.userUUID].user.startIllness)) {
          this.isFullDay = false;
          this.startTime = moment(store.state.group[this.userUUID].user.startIllness).format('HH:mm');
          this.endTime = moment(store.state.group[this.userUUID].user.endIllness).format('HH:mm');
        }
      }
    },
    deleteHolidays() {
      this.setSaveHolidays(undefined, undefined);
      this.state.group[this.userUUID].user.activity = "Available";
      this.closeModal();
    },
    saveHolidays() {
      try {
        const startHolidaysTimeStamp = new Date(this.startHolidays)
        if (startHolidaysTimeStamp < new Date()) {
          // if the current date is higher than the start holidays setted, set activity to holidays automatically
          this.state.group[this.userUUID].user.activity = this.type;
        }
        let startHolidays = moment(this.startHolidays).toISOString(true).slice(0, 10);
        let endHolidays = moment(this.endHolidays).toISOString(true).slice(0, 10);
        const representative = this.representative && this.representative.value ? { 
          value: this.representative.value,
          text: this.representative.text 
        } : null;
        // Partial-day absence tracking: #512
        if (!this.isFullDay) {
          startHolidays = moment(this.startHolidays).hours(this.startTime.split(':')[0]).minutes(this.startTime.split(':')[1]).seconds(0).milliseconds(0).toISOString(true);
          endHolidays = moment(this.endHolidays).hours(this.endTime.split(':')[0]).minutes(this.endTime.split(':')[1]).seconds(0).milliseconds(0).toISOString(true);
        }
        store.setSaveHolidaysRepresentative(startHolidays, endHolidays, representative, this.userUUID, this.type);
        this.closeModal();
      } catch (err) {
        console.error('saveHolidays Err:', err);
      }
    },
    setSaveHolidays(startDate, endDate) {
      store.setSaveHolidays(startDate, endDate, this.userUUID, this.type);
    },
    selectItemOnBlur() {
      if (this.filteredItems?.length == 1) {
        this.representative = this.filteredItems[0];
      }
    },
  },
  computed: {
    checkDisableType() {
      if (this.calendarStatus) return true;
      // if (this.state.group[this.userUUID].user.startHolidays || this.state.group[this.userUUID].user.startIllness) {
      //   return true;
      // }
      return false;
    },
    getStatus() {
      return this.state.user.activity;
    },
    getFirstDayOfThisYear() {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), 0, 1); // January 1st of the current year

      const day = String(firstDay.getDate()).padStart(2, '0');
      const month = String(firstDay.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = firstDay.getFullYear();
      const hours = String(firstDay.getHours()).padStart(2, '0');
      const minutes = String(firstDay.getMinutes()).padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
    getStartHolidaysFormatted() {
      if (this.startHolidays) {
        return moment(this.startHolidays).format("DD.MM.YYYY");
      }
      return null;
    },
    getEndHolidaysFormatted() {
      if (this.endHolidays) {
        return moment(this.endHolidays).format("DD.MM.YYYY");
      }
      return null;
    },
    disabledSave() {
      if ((!this.startHolidays || !this.endHolidays) || (!this.isFullDay && (!this.startTime || !this.endTime))) {
        return true;
      }
      return false;
    },
    dataComboboxAllUsers() {
      const membersList = JSON.parse(JSON.stringify(this.state.group));
      const selectedUser = store.state.group[this.userUUID].user;
      if (selectedUser?.uuid && membersList && membersList[selectedUser?.uuid]) {
        delete membersList[selectedUser?.uuid]
      }
      let finalResult = [];
      for (let uuid in membersList) {
        let person = membersList[uuid];
        if (person && person.user && person.user.name && person.user.uuid && hasPrivilege(person.user.uuid)) {
          const item = {
            value: person.user.uuid,
            text: person.user.name,
          };
          finalResult.push(item);
        }
      }
      return finalResult;
    },
    filteredItems() {
      if (!this.searchUsers) return;
      return this.dataComboboxAllUsers.filter(item => 
        item.text.toLowerCase().includes(this.searchUsers.toLowerCase()) || 
        item.value.toLowerCase().includes(this.searchUsers.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss">
.holidaysDatePickers{
  .v-picker.v-sheet{
      overflow-y: auto !important;
  }
  .v-date-picker-years{
    overflow: hidden !important;
  }
}
</style>