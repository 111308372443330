<template>
  <v-card flat class="mx-auto" width="100%">
    <UsersSearch
      :setList="setList"
      :active="active"
      :filterPerson="filterPerson"
      :setCurrentContentVisile="setCurrentContentVisile"
      :isGuest="isGuest"
      :sortedPersons="sortedPersons"
      :totalUnfilteredUsers="totalUnfilteredUsers"
      :searchFoundSomethingPerson="searchFoundSomethingPerson"
      :isMobile="isMobile"
      v-if="searchFoundSomethingPerson && state.showSearch"
    />
  </v-card>
</template>    

<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import UsersSearch from "./usersSearch.vue";

export default {
  components: {
    UsersSearch
  },
  props:['setList', 'active'],
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    isMobile: isMobile()
  }),
  methods: {
    filterPerson: function(person) {
      return (
        person.uuid != this.ownUUID &&
        (this.state.searchTerm.length === 0 ||
          `${(person.user.name || "")}`.trim().toLowerCase().replace(/\s{2,}/g, ' ').indexOf(
            this.state.searchTerm.trim().toLowerCase().replace(/\s{2,}/g, ' ')
          ) !== -1)
      );
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    }
  },
  computed: {
    searchFoundSomethingPerson() {
      return (
        this.state.searchTerm.length >= 3 && this.sortedPersons.length > 0
      );
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    sortedPersons: function() {
      const persons = [];
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (!person || 
        !person.user || 
        !person.user.email || person.user.email =='' ||
        person.user.name == "" || person.user.visitor ) 
         continue;
        persons.push({ ...person, uuid });
      }
      return persons.filter(this.filterPerson).sort((a, b) => {
        if (a.connected && !b.connected) {
          return -1;
        } else if (!a.connected && b.connected) {
          return 1;
        }
        return ((a.user || {}).name || a.uuid || "").localeCompare(
          (b.user || {}).name || b.uuid || ""
        );
      });
    },
    totalUnfilteredUsers: function() {
      let total = 0;
      for (let uuid in store.state.group) {
        const exists = store.getUuidFromFavorites(uuid);
        if (exists) {
          const person = store.state.group[uuid];
          if (!person || !person.user) continue;
          ++total;
        }
      }
      return total;
    }
  }
};
</script>
<style scoped lang="scss">
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item__title {
  line-height: 2.5 !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-expansion-panel-header {
  padding: 0px 25px 0px 16px !important;
}
.v-expansion-panel-header__icon {
  min-width: 48px !important;
  margin-right: 8px !important;
}
</style>